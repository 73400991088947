///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Intro */

	#intro {
		.logo {
			border-bottom: 0;
			display: inline-block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
			overflow: hidden;
			position: relative;
			background-position: 500px 500px;


			img {
				display: block;
				border-radius: 50%;
				height: 10em;
				width: 10em;
				object-fit: cover;
				object-position: 0 0;
				pointer-events: none;
				touch-action: none;
			}
		}

		header {
			h2 {
				font-size: 1.75em;
				font-weight: _font(weight-heading-extrabold);
			}

			p {
				font-size: 0.8em;
			}
		}

		@include breakpoint('<=large') {
			margin: 0 0 _size(section-spacing) 0;
			text-align: center;

			header {
				h2 {
					font-size: 2em;
				}

				p {
					font-size: 0.7em;
				}
			}
		}

		@include breakpoint('<=small') {
			margin: 0 0 _size(section-spacing-small) 0;
			padding: 1.25em 0;

			> :last-child {
				margin-bottom: 0;
			}

			.logo {
				margin: 0 0 (_size(element-margin) * 0.25) 0;
			}

			header {
				h2 {
					font-size: 1.25em;
				}

				> :last-child {
					margin-bottom: 0;
				}
			}
		}
	}