///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Awards */

	.awards-table {
		border-collapse: inherit;
		background-color: white;
	}

	.awards {
		//@include vendor('display', 'flex');
		//@include vendor('flex-direction', 'column-reverse');
		background: _palette(bg);
		//border: solid 1px _palette(border);
		margin: 0 0 _size(element-margin) 0;

		.image {
			overflow: hidden;
			width: 100%;

			img {
				@include vendor('transition', 'transform #{_duration(transition)} ease-out');
				width: 100%;
			}

			&:hover {
				img {
					@include vendor('transform', 'scale(1.05)');
				}
			}
		}

		&:nth-child(2n + 1) {
			//background-color: white;
		}

		td {
			//@include padding(1em, 1em, (0, 3em, 0, 0));
			//min-height: 4em;
			//padding: 0;
			position: relative;
			//@include vendor('flex-grow', '1');
			vertical-align: middle;
			//border-top: solid 1px _palette(border);

			//border-bottom: 1px solid;

			h2 {
				margin-bottom: 0;
				margin-top: 0.5em;
			}

			.published {
				display: block;
				font-family: _font(family-heading);
				font-size: 0.7em;
				font-weight: _font(weight-heading);
				letter-spacing: _font(kerning-heading);
				//margin:  -0.625em 0 (_size(element-margin) * 0.85) 0;
				text-transform: uppercase;
			}

			.author {
				position: absolute;
				right: 1em;
				top: 0.35em;
				font-size: 2em;
			}

		}
		.award-icon {
			font-size: 2em;
		}

	}

	.awards {
		margin: 0 0 _size(element-margin) 0;

		@include breakpoint('<=large') {
			//@include vendor('display', 'flex');
			//@include vendor('flex-wrap', 'wrap');
			width: calc(100% + #{_size(element-margin)});

			> * {
				margin: _size(element-margin) _size(element-margin) 0 0;
				width: calc(50% - #{_size(element-margin)});
			}

			> :nth-child(-n + 2) {
				margin-top: 0;
			}
		}

		@include breakpoint('<=xsmall') {
			display: block;
			width: 100%;

			> * {
				margin: 0 0 _size(element-margin) 0;
				width: 100%;
			}
		}
	}